<template>
<div>
<el-dialog
      title="Grin Withdraw(Slatepack) - 1"
      :visible.sync="dialogVisible"
      width="400"
      :before-close="handleClose"
      :close-on-click-modal="false"
  >
  <p style="color: #893EFF; margin: 10px 0" v-if="info">
      INFO: {{ info }}
  </p>
  <p style="color: #893EFF; margin: 10px 0" v-if="error">
      Error: {{ error }}
  </p>
    <p style="color: #893EFF; margin: 10px">Please paste slatepack message generated by your wallet</p>
    <el-input
      type="textarea"
      placeholder="your response slatepack message"
      :rows="20"
      v-model="slatepack2">
    </el-input>

    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal" v-if='ok'>Finish</el-button>
      <el-button @click="closeModal" v-else>Cancel</el-button>

      <el-button type="primary" @click="finalize">Finalize</el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>
import {finalizeTx} from '../libs/api.js'

export default {
  name: "Slatepack2",
  data() {
    return {
      error: '',
      slatepack2: null,
      ok: false,
      info: '',
      error: '',
    }
  },
  watch: {
    error:function(newVal){
      if(newVal){
        setTimeout(()=> this.error = '', 
        5*1000)
      }
    }
  },
  computed: {
    openSlatepack2(){
      return this.$store.state.openSlatepack2
    },

    dialogVisible(){
      return this.$store.state.openSlatepack2
    },
    
    slatepackToDispaly: function() {
      if(this.slatepack2){
        let res = this.slatepack2.split(' ')
        let head = res.shift()
        let end = res.pop()
        let result = [head, ]
        let line = []
        for (let i = 0; i < res.length; i++) {
          if(i%3 === 0){
            if(line.length>0)result.push(line.join(' '))
            line = [res[i]]
          }else{
            line.push(res[i])
          }
        }
        result.push(end)
        return result
      }else{
        return null
      }
    }
  },

  methods: {
    handleClose(){},
    closeModal() {
      this.slatepack2 = null
      this.error = ''
      this.ok = false
      this.info = ''
      this.error = ''
      this.$store.commit('closeSlatepack2')
    },

    validateSlatepack(slatepack){
      if(!slatepack.startsWith('BEGINSLATEPACK'))return false
      return true
    },

    paste(evt){
      let slatepack = evt.clipboardData.getData('text')
      if(this.validateSlatepack(slatepack)){
        this.slatepack2 = slatepack
      }else{
        this.error = 'Invalid response slatepack'
      }
    },

    async finalize(){
      try{
        let tx_slate_id = this.$store.state.slatepack.id
        console.log(tx_slate_id)
        let resp = await finalizeTx(tx_slate_id, this.slatepack2)
       
        if(resp.data === 'ok' ){
          this.$messageBus.$emit('finalize-post')
          this.info = 'Tx finalize successful.'
          this.ok = true
        }

      }catch(error){
        if(error.response){
          //console.log(error.response.data)
          if(error.response.data.message === 'bad slatepack' ){
            this.error = 'Invalid respose slatepack'
            return
          }
          this.error = 'Something is wrong. Try later.'
        }
      }
    }
  }
}
</script>
<style>
#slatepack_box {
  height:400px;
  overflow-y: scroll;
  border-style:dashed; 
  border-width:2px;
  border-color:#dbdbdb;
  background-color:#f6f9fe;
  outline: none;
}
</style>
