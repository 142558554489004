<template>
  <div :class="$isWeb ? 'history-card-h5' : 'history-card'">
    <div class="history-container">
      <span class="name">Recent withdrawal history</span>
    </div>
    <div class="history-detail">
      <span class="btn item-btn" v-if="!txs">No withdrawal record</span>
    </div>
    <div class="table-container" v-if="txs">
      <table>
        <thead class="thead">
          <tr>
            <th class="th">Time</th>
            <th class="th">Exchange</th>
            <th class="th">Tx-Slate-ID</th>
            <th class="th">Status</th>
            <th class="th">Height</th>
            <th class="th">Amount/Fee</th>
            <th class="th">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tx, index) in txs" :key="index">
            <td>
              <span class="td"> {{ tx.timestamp }}</span>
            </td>
            <td>
              <span class="td"> {{ tx.exchange }} </span>
            </td>
            <td>
              <span class="td"> {{ tx.slate_id }} </span>
            </td>
            <td>
              <span class="td"> {{ tx.status_ }}</span>
            </td>
            <td>
              <span class="td" v-if="tx.height === '--'">{{ tx.height }}</span>
              <a class="td" :href="grinBlockLink(tx.block_height)" v-else>{{
                tx.height
              }}</a>
            </td>
            <td>
              <span class="td">{{ tx.amount_ }} / 0.1 ツ</span>
            </td>
            <td v-if="tx.status === 's1'">
              <a @click="openSlatepack" href="#">slatepack</a> &nbsp;
              <a @click="openSlatepack2" href="#">finalize</a>
            </td>
            <td v-else-if="tx.status ==='confirmed'">
              <a @click="copyTxInfo(index, tx.tx_slate_id, tx.block_hash)" >
                <span v-if="txCopied===index">Copied</span>
                <span v-else style="text-decoration: underline #893eff;">Copy tx info</span>
              </a>
            </td>
            <td v-else><span class="td">--</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getWithdrawHistory } from "../../libs/api.js";
const moment = require("moment");

export default {
  data() {
    return {
      txs: null,
      copied: null,
      txCopied: null
    };
  },
  watch: {
    copied: function(val) {
      if (val !== false) {
        setTimeout(() => {
          this.copied = false;
        }, 3 * 1000);
      }
    },
    txCopied: function(val) {
      if (val !== false) {
        setTimeout(() => {
          this.txCopied = false;
        }, 3 * 1000);
      }
    },
  },
  methods: {
    copyTxInfo(index, tx_slate_id, block_hash){
      let msg = `Tx-slate-id: ${tx_slate_id} \n\nblock hash: ${block_hash}`
      this.$copyText(msg).then(()=>{
        this.txCopied = index
      })
    },

    copySlateID(index, tx_slate_id) {
      this.$copyText(tx_slate_id).then(() => {
        this.copied = index;
      });
    },

    grinBlockLink(height) {
      return "https://grinscan.net/block/" + height;
    },

    shortSlateID(sid, count) {
      return sid.slice(0, count) + "..." + sid.slice(-count);
    },
    openSlatepack() {
      this.$store.commit("openSlatepack");
    },
    openSlatepack2() {
      this.$store.commit("openSlatepack2");
    },
    async getTxs() {
      try {
        let resp = await getWithdrawHistory();
        let this_ = this;
        if (resp.data.length > 0) {
          this.txs = resp.data.map((t) => {
            t.timestamp = moment(t.created_at).format("YYYY-MM-DD HH:mm");
            if (t.tx_slate_id) {
              t.slate_id = this.shortSlateID(t.tx_slate_id, 6);
            } else {
              t.slate_id = "--";
            }
            t.height = t.block_height === 0 ? "--" : t.block_height;
            t.amount_ = t.amount / 1000000000;
            if (t.status === "s1") {
              t.status_ = "wait for finalize";
              let slatepack = {
                id: t.tx_slate_id,
                s1: t.s1,
              };
              this_.$store.commit("setSlatepack", slatepack);
            }

            if (t.status === "to_post") {
              t.status_ = "Wait for post";
            }

            if (t.status === "posted") {
              t.status_ = "Tx posted";
            }

            if (t.status === "onchain") {
              t.status_ = "Onchain, wait for confirm";
            }

            if (t.status === "confirmed") {
              t.status_ = "confirmed";
            }

            if (t.status === "timeout") {
              t.status_ = "timeout";
            }

            if (t.status == "canceled") {
              t.status_ = "canceled";
            }
            return t;
          });
          console.log(this.txs);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    this.getTxs();
    this.$messageBus.$on("withdraw-inited", async () => {
      this.getTxs();
      await this.$store.dispatch("updateBalance");
    });
    this.$messageBus.$on("withdraw2exchange-inited", async () => {
      this.getTxs();
      await this.$store.dispatch("updateBalance");
    });
    this.$messageBus.$on("finalize-post", async () => {
      this.getTxs();
      await this.$store.dispatch("updateBalance");
    });
  },
};
</script>
<style lang="less" scoped>
.history-card {
  box-sizing: border-box;
  width: 1200px; /*no*/
  background: #fff;
  margin: 0 auto;
  background: url("../../assets/img/login/history_card_bg.png") no-repeat top
    center;
  background-size: cover;
  padding: 0 130px; /*no*/
  padding-top: 105px; /*no*/
  margin-top: 53px; /*no*/
  .history-container {
    height: 50px; /*no*/
    margin-bottom: 28px; /*no*/
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: inline-block;
    }
    .avator {
      width: 50px; /*no*/
      height: 50px; /*no*/
    }
    .name {
      font-size: 22px; /*no*/
      font-weight: 500;
      line-height: 27px; /*no*/
      color: #101010;
    }
  }
  .history-detail {
    position: relative;
    padding-top: 43px; /*no*/
    border-top: 1px solid #893eff;
    display: flex;
    justify-content: center;
    .btn {
      display: inline-block;
      height: 40px; /*no*/
      line-height: 40px; /*no*/
      background-color: #893eff;
      color: #fff;
      font-size: 18px; /*no*/
      font-weight: bold;
      padding: 0 14px; /*no*/
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 37px; /*no*/
      span {
        display: inline-block;
      }
    }
    .item-title {
      font-size: 18px; /*no*/
      line-height: 21px; /*no*/
      color: #676767;
      width: 247px; /*no*/
      text-align: right;
    }
    .item-value {
      box-sizing: border-box;
      width: 436px; /*no*/
      height: 40px; /*no*/
      padding: 10px 12px; /*no*/
      font-size: 16px; /*no*/
      border: 1px solid #b7b7b7;
      margin: 0 20px; /*no*/
    }
    .item-text {
      margin: 0 20px; /*no*/
      width: 436px; /*no*/
      height: 40px; /*no*/
      color: #893eff;
      line-height: 40px; /*no*/
    }
    .item-desc {
      padding-left: 266px; /*no*/
      line-height: 19px; /*no*/
      font-size: 15px; /*no*/
      color: #676767;
    }
    .handle-btn {
      height: 40px; /*no*/
      padding-left: 266px; /*no*/
      margin-top: 36px; /*no*/
      .btn-disable {
        background-color: rgba(137, 62, 255, 0.3);
      }
    }
  }
  .table-container {
    table {
      width: 100%;
    }
    .th {
      height: 19px; /*no*/
      font-size: 16px; /*no*/
      font-weight: bold;
      line-height: 19px; /*no*/
      border-bottom: 1px solid #ebeef5; /*no*/
      padding: 12px 0; /*no*/
    }
    .border-th {
      border: 1px solid #893eff; /*no*/
      font-size: 16px; /*no*/
      font-weight: 500;
      line-height: 19px; /*no*/
      color: #fff;
      color: #893eff;
    }
    .th-active {
      background-color: #893eff;
      font-size: 16px; /*no*/
      font-weight: 500;
      line-height: 19px; /*no*/
      color: #fff;
    }
    .td {
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 17px 0; /*no*/
    }
    .border-td {
      border: 1px solid #cccccc; /*no*/
    }
    .td-input {
      box-sizing: border-box;
      width: 211px; /*no*/
      height: 40px; /*no*/
      padding: 10px 12px; /*no*/
      font-size: 16px; /*no*/
      border: 1px solid #b7b7b7; /*no*/
    }
    .thead {
      padding: 12px 0; /*no*/
    }
  }
}
.history-card-h5 {
  box-sizing: border-box;
  width: 625px;
  background: #fff;
  margin: 0 auto;
  padding: 80px 50px;
  margin-top: 30px;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);
  .history-container {
    height: 32px;
    margin-bottom: 41px;
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: inline-block;
    }
    .avator {
      width: 50px;
      height: 50px;
    }
    .name {
      font-size: 27px;
      font-weight: 600;
      line-height: 32px;
      color: #101010;
    }
  }
  .history-detail {
    position: relative;
    padding-top: 43px;
    border-top: 1px solid #893eff;
    display: flex;
    justify-content: center;
    .btn {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      background-color: #893eff;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      padding: 0 14px;
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 37px;
      span {
        display: inline-block;
      }
    }
    .item-title {
      font-size: 18px;
      line-height: 21px;
      color: #676767;
      width: 247px;
      text-align: right;
    }
    .item-value {
      box-sizing: border-box;
      width: 436px;
      height: 40px;
      padding: 10px 12px;
      font-size: 16px;
      border: 1px solid #b7b7b7;
      margin: 0 20px;
    }
    .item-text {
      margin: 0 20px;
      width: 436px;
      height: 40px;
      color: #893eff;
      line-height: 40px;
    }
    .item-desc {
      padding-left: 266px;
      line-height: 19px;
      font-size: 15px;
      color: #676767;
    }
    .handle-btn {
      height: 40px;
      padding-left: 266px;
      margin-top: 36px;
      .btn-disable {
        background-color: rgba(137, 62, 255, 0.3);
      }
    }
  }
  .table-container {
    width: 517px;
    overflow: scroll;
    table {
      width: 100%;
    }
    .th {
      height: 19px;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      border-bottom: 1px solid #ebeef5;
      padding: 12px;
      min-width: 180px;
    }
    .border-th {
      border: 1px solid #893eff;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: #fff;
      color: #893eff;
    }
    .th-active {
      background-color: #893eff;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: #fff;
    }
    .td {
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 17px 0;
    }
    .border-td {
      border: 1px solid #cccccc;
    }
    .td-input {
      box-sizing: border-box;
      width: 211px;
      height: 40px;
      padding: 10px 12px;
      font-size: 16px;
      border: 1px solid #b7b7b7;
    }
    .thead {
      padding: 12px 0;
    }
  }
}
</style>
