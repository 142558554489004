<template>
  <div class="withdrawal">
    <Banner :space="30" />
    <div :class="$isWeb ? 'withdrawal-container-h5' : 'withdrawal-container'">
      <Card />
      <ExChangeCard />
      <HistoryCard />
    </div>
    <Footer />
    
    <Slatepack/>
    <Slatepack2/>

  </div>
</template>

<script>
import Slatepack from '@/components/Slatepack.vue'
import Slatepack2 from '@/components/Slatepack2.vue'

import Footer from '@/components/layout/Footer.vue'

import Card from './Card.vue'
import ExChangeCard from './ExChangeCard.vue'
import HistoryCard from './HistoryCard.vue'

import Banner from './Banner.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    Card,
    Footer,
    ExChangeCard,
    HistoryCard,
    Slatepack,
    Slatepack2
  },

  async created(){
    await this.$store.dispatch('getGrinAddress')
    await this.$store.dispatch('updateBalance')
  }
  
}
</script>
<style lang="less">
  .withdrawal{
      width: 100%;
  }
  .withdrawal-container{
      position: relative;
      padding-top: 247px; /*no*/
      min-width: 1120px; /*no*/
      padding-bottom: 90px; /*no*/
  }
  .withdrawal-container-h5{
      position: relative;
      padding-top: 589px;
    //   width: 626px;
      padding-bottom: 90px;

  }
</style>
