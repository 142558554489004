<template>
  <div :class="$isWeb ? 'exchange-card-h5' : 'exchange-card'">
    <div class="exchange-container">
      <span class="avator">
        <img width="100%" src="../../assets/img/home/smile.png" />
      </span>
      <span class="name">Withdraw grin to exchange</span>
    </div>

    <p style="color: #893EFF; margin: 10px 0" v-if="error">
      ERROR: {{ error }}
    </p>
    <p style="color: #893EFF; margin: 10px 0" v-if="info">INFO: {{ info }}</p>

    <div class="exchange-detail">
      <div class="item">
        <label class="item-title" style="margin-bottom: 0">Exchange</label>
        <OSelect />
      </div>

      <div v-if="!grinAddress">
        <div class="item">
          <label class="item-title">Grin address</label>
          <input class="item-value " v-model="grinAddressToBind" />
        </div>
        <template v-if="$isWeb">
          <div class="item">
            <label class="item-title item-title-1">Memo</label>
            <input class="item-value item-value-1" v-model="memoToBind" />
            <span
              class="btn item-btn item-btn-1 "
              :style="{ 'margin-left': $isWeb ? '0px' : '20px' }"
              @click="bindGrinAddress"
              >Bind</span
            >
          </div>
        </template>
        <template v-else>
          <div class="item">
            <label class="item-title item-title-1">Memo</label>
            <input class="item-value item-value-1" v-model="memoToBind" />
          </div>

          <div class="item">
            <label class="item-title"></label>
            <span
              class="btn item-btn"
              :style="{ 'margin-left': $isWeb ? '0px' : '20px' }"
              @click="bindGrinAddress"
              >Bind</span
            >
          </div>
        </template>
      </div>

      <div v-else>
        <div class="item">
          <label class="item-title">Grin address</label>
          <input class="item-value" v-model="grinAddress" disabled />
        </div>
        <template v-if="$isWeb">
          <div class="item">
            <label class="item-title item-title-1">Memo</label>
            <input class="item-value item-value-1" v-model="memo" disabled />
            <span
              class="btn item-btn item-btn-1"
              :style="{ 'margin-left': $isWeb ? '0px' : '20px' }"
              @click="reset"
              >Reset Address</span
            >
          </div>
        </template>
        <template v-else>
          <div class="item">
            <label class="item-title item-title-1">Memo</label>
            <input class="item-value item-value-1" v-model="memo" disabled />
          </div>

          <div class="item">
            <label class="item-title"></label>
            <span
              class="btn item-btn"
              :style="{ 'margin-left': $isWeb ? '0px' : '20px' }"
              @click="reset"
              >Reset Address</span
            >
          </div>
        </template>
      </div>

      <div class="item">
        <label class="item-title">Withdrawable/locked amount</label>
        <span class="item-text">{{ available }} / {{ locked }} ツ</span>
      </div>
      <div class="item" style="margin-bottom: 10px;">
        <label class="item-title item-title-1">Withdrawal Amount</label>
        <input
          class="item-value item-value-2"
          v-model.number="amount"
          @keyup="handleAmount"
        />
        <span class="btn item-btn item-btn-1" @click="withdrawAllToGate"
          >Withdraw all</span
        >
      </div>
      <p class="item-desc">
        Minimum withdrawal amount 5ツ；<br />
        Withdrawal fee 0.1ツ
      </p>
      <div class="handle-btn" v-if="withdrawable">
        <span class="btn" style="margin-right: 53px" @click="withdraw"
          >Start withdraw</span
        >
      </div>
      <div class="handle-btn" v-else>
        <span class="btn btn-disable" style="margin-right: 53px"
          >Start withdraw</span
        >
      </div>
    </div>
    <Email4Verify />
  </div>
</template>
<script>
import OSelect from "@/components/layout/Select.vue";
import Email4Verify from "@/components/Email4Verify.vue";
import {
  minWithdrawAmount,
  withdrawFee,
  canWithdraw2gate,
  canWithdraw2hotbit,
  canWithdraw2bitforex,
} from "@/config";
import {
  getGrinAddress,
  getCodeToBindGateGrinAddress,
  withdraw2excahnge,
  deleteGrinAddress,
  getCodeToBindHTTPGrinAddress,
} from "../../libs/api.js";

export default {
  props: {},
  components: {
    OSelect,
    Email4Verify,
  },
  data() {
    return {
      error: "",
      info: "",
      grinAddress: "",
      memo: "",
      grinAddressToBind: "",
      memoToBind: "",
      amount: 0,
      fee: withdrawFee,
      min: minWithdrawAmount,
      exchange: "gate",
    };
  },

  watch: {
    error: function(newVal) {
      if (newVal) {
        setTimeout(() => (this.error = ""), 5 * 1000);
      }
    },
    info: function(newVal) {
      if (newVal) {
        setTimeout(() => (this.info = ""), 8 * 1000);
      }
    },
  },

  computed: {
    total() {
      return this.$store.state.total;
    },
    locked() {
      return this.$store.state.locked;
    },
    available() {
      return this.$store.state.available;
    },
    withdrawable() {
      if (this.exchange === "gate" && !canWithdraw2gate) return false;
      if (this.exchange === "hotbit" && !canWithdraw2hotbit) return false;
      if (this.exchange === "bitforex" && !canWithdraw2bitforex) return false;
      if (this.exchange == "gate") {
        return (
          this.available - 1 > minWithdrawAmount &&
          this.amount + withdrawFee <= this.available - 1 &&
          this.amount >= minWithdrawAmount && //&& (this.amount <= 5000)
          this.grinAddress &&
          this.amount
        );
      }

      return (
        this.available > minWithdrawAmount &&
        this.amount + withdrawFee <= this.available &&
        this.amount >= minWithdrawAmount &&
        this.amount <= 5000 &&
        this.grinAddress &&
        this.amount
      );
    },
  },
  async created() {
    if (this.exchange) {
      await this.getGrinAddress_(this.exchange);
    }

    this.$messageBus.$on("verifiedByEmail", async (purpose) => {
      if (purpose === "bindGateGrinAddress") {
        await this.getGrinAddress_(this.exchange);
      }

      if (purpose === "bindHTTPGrinAddress") {
        await this.getGrinAddress_(this.exchange);
      }
    });
  },

  methods: {
    async reset() {
      try {
        let resp = await deleteGrinAddress(this.exchange);
        if (resp.status == 204) {
          await this.getGrinAddress_(this.exchange);
          this.info = "Address reseted.";
        } else {
          this.error = "Something wrong. Try later.";
        }
      } catch (error) {
        this.error = "Something wrong. Try later.";
      }
    },

    async getGrinAddress_(exchange) {
      try {
        let resp = await getGrinAddress(exchange);
        if (resp.data) {
          this.grinAddress = resp.data.address;
          if (resp.data.exchange === "gate") {
            this.memo = resp.data.memo;
          }
        } else {
          this.grinAddress = "";
          this.memo = "";
        }
      } catch (error) {
        if (error.response) {
          if (error.response.data.message === "no address") {
            this.grinAddress = "";
          }
        }
      }
    },

    changeEx() {
      this.getGrinAddress_(this.exchange);
    },

    openEmail4Verify(purpose) {
      this.$store.commit("openEmail4Verify", purpose);
    },

    validateGateGrinAddress(address) {
      return address.startsWith("grin");
    },

    validateHTTPGrinAddress(address) {
      return address.startsWith("http");
    },

    validateMemo(memo) {
      const pattern = /^\d+$/;
      return pattern.test(memo); // returns a boolean
    },

    async bindGrinAddress() {
      this.grinAddressToBind = this.grinAddressToBind.trim();
      this.memoToBind = this.memoToBind.trim();

      if (this.exchange === "gate") {
        if (!this.validateGateGrinAddress(this.grinAddressToBind)) {
          this.error = "Invalid grin address";
          return;
        }
        if (!this.validateMemo(this.memoToBind)) {
          this.error = "Invalid memo";
          return;
        }

        try {
          let resp = await getCodeToBindGateGrinAddress(
            this.grinAddressToBind,
            this.memoToBind
          );
          if (resp.data === "ok") {
            this.openEmail4Verify("bindGateGrinAddress");
          } else {
            this.error = "Something wrong. Try later.";
          }
        } catch (error) {
          if (error.response) {
            let msg = error.response.data.message;
            if (msg === "post email too frequently") {
              this.error = "Request too frequently. Try later.";
              return;
            }
          }
          this.error = "Something wrong. Try later.";
        }
      }

      if (this.exchange === "hotbit" || this.exchange === "bitforex") {
        if (!this.validateHTTPGrinAddress(this.grinAddressToBind)) {
          this.$store.commit("addError", "Grin地址错误");
          return;
        }

        try {
          let resp = await getCodeToBindHTTPGrinAddress(
            this.grinAddressToBind,
            this.exchange
          );
          if (resp.data === "ok") {
            this.openEmail4Verify("bindHTTPGrinAddress");
          } else {
            this.$store.commit("addError", "Failed to bind grin address, Try later.");
          }
        } catch (error) {
          if (error.response) {
            let msg = error.response.data.message;
            if (msg === "post email too frequently") {
              this.$store.commit(
                "addError",
                "Request too frequently. Try later."
              );
              return;
            }
          }
          this.$store.commit("addError", "Failed to bind grin address, Try later.");
        }
      }
    },

    handleAmount(e) {
      this.amount = parseInt(e.target.value) || 0;
    },

    handleAmount2(e) {
      //2位尾数
      const precision = 1;
      const re = eval("/^\\d*(\\.?\\d{0," + precision + "})/g");
      e.target.value = e.target.value.match(re)[0] || null;
    },

    withdrawAll() {
      if (this.available - withdrawFee < 0) {
        return 0;
      }
      this.amount = this.available - withdrawFee;
    },

    withdrawAllToGate() {
      if (this.available - withdrawFee < 0) {
        return 0;
      }
      this.amount = parseInt(this.available - withdrawFee - 1);
    },

    async withdraw() {
      try {
        await withdraw2excahnge(this.amount, this.exchange);
        this.$messageBus.$emit("withdraw2exchange-inited");
        this.info = "Request withdraw successful.";
      } catch (error) {
        if (error.response) {
          //console.log(error.response.data)
          if (error.response.data.message === "have unconfirmed tx") {
            this.error = "The last withdraw have not finished.";
            return;
          }
          this.error = "Something is wrong. Try later.";
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.exchange-card {
  box-sizing: border-box;
  width: 1210px; /*no*/
  height: 864px; /*no*/
  background: #fff;
  margin: 0 auto;
  background: url("../../assets/img/home/card_bg4.png") no-repeat top center;
  background-size: cover;
  padding: 0 130px; /*no*/
  padding-top: 105px; /*no*/
  margin-top: 53px; /*no*/
  .exchange-container {
    height: 50px; /*no*/
    margin-bottom: 28px; /*no*/
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: inline-block;
    }
    .avator {
      width: 50px; /*no*/
      height: 50px; /*no*/
    }
    .name {
      margin-left: 20px; /*no*/
      font-size: 22px; /*no*/
      font-weight: 500;
      line-height: 27px; /*no*/
      color: #101010;
    }
  }
  .exchange-detail {
    position: relative;
    padding-top: 43px; /*no*/
    border-top: 1px solid #893eff;
    .btn {
      display: inline-block;
      height: 40px; /*no*/
      line-height: 40px; /*no*/
      background-color: #893eff;
      color: #fff;
      font-size: 18px; /*no*/
      font-weight: bold;
      padding: 0 14px; /*no*/
      cursor: pointer;
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 37px; /*no*/
      span {
        display: inline-block;
      }
    }
    .item-title {
      font-size: 18px; /*no*/
      line-height: 21px; /*no*/
      color: #676767;
      width: 247px; /*no*/
      text-align: right;
    }

    .item-value {
      box-sizing: border-box;
      width: 436px; /*no*/
      height: 40px; /*no*/
      padding: 10px 12px; /*no*/
      font-size: 16px; /*no*/
      border: 1px solid #b7b7b7;
      margin: 0 20px; /*no*/
    }
    .item-text {
      margin: 0 20px; /*no*/
      width: 436px; /*no*/
      height: 40px; /*no*/
      color: #893eff;
      line-height: 40px; /*no*/
    }
    .item-desc {
      padding-left: 266px; /*no*/
      line-height: 19px; /*no*/
      font-size: 15px; /*no*/
      color: #676767;
    }
    .handle-btn {
      height: 40px; /*no*/
      padding-left: 266px; /*no*/
      margin-top: 36px; /*no*/
      .btn-disable {
        background-color: rgba(137, 62, 255, 0.3);
      }
    }
    .item-select {
      margin-left: 20px; /*no*/
      width: 190px; /*no*/
      input {
        border: 1px solid #b7b7b7;
      }
      select {
        border: none;
        outline: none;
        width: 100%;
        height: 40px; /*no*/
        line-height: 40px; /*no*/
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-left: 20px; /*no*/
      }
      &:after {
        content: "";
        width: 14px; /*no*/
        height: 8px; /*no*/
        background: url(../../assets/img/home/arrow-down.png) no-repeat center;
        position: absolute;
        right: 20px; /*no*/
        top: 41%;
        pointer-events: none;
      }
    }
  }
}
.exchange-card-h5 {
  box-sizing: border-box;
  width: 625px;
  height: 864px;
  background: #fff;
  margin: 0 auto;
  padding: 0 50px;
  padding-top: 54px;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);
  .exchange-container {
    height: 50px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: inline-block;
    }
    .avator {
      width: 50px;
      height: 50px;
    }
    .name {
      margin-left: 13px;
      font-size: 27px;
      font-weight: 500;
      line-height: 32px;
      color: #101010;
    }
  }
  .exchange-detail {
    position: relative;
    padding-top: 43px;
    border-top: 1px solid #893eff;
    .btn {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      background-color: #893eff;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      padding: 0 14px;
      cursor: pointer;
    }
    .item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 40px;
      span {
        display: inline-block;
      }
    }
    .item-title {
      font-size: 18px;
      line-height: 21px;
      color: #676767;
      margin-bottom: 16px;
    }
    .item-title-1 {
      width: 400px;
    }
    .item-btn-1 {
      width: 138px;
      text-align: center;
    }

    .item-value {
      box-sizing: border-box;
      width: 517px;
      height: 40px;
      padding: 0px 12px;
      font-size: 16px;
      border: 1px solid #b7b7b7; /*no*/
    }
    .item-value-1 {
      width: 330px;
      margin-right: 20px;
    }
    .item-value-2 {
      width: 330px;
      margin-right: 20px;
    }
    .item-text {
      font-size: 20px;
      margin-left: 20px;
      color: #893eff;
      line-height: 24px;
    }
    .item-desc {
      line-height: 19px;
      font-size: 15px;
      color: #676767;
    }
    .handle-btn {
      height: 40px;
      margin-top: 36px;
      margin-left: 182px;
      .btn-disable {
        background-color: rgba(137, 62, 255, 0.3);
      }
    }
    .item-select {
      margin-left: 20px;
      width: 190px;
      input {
        border: 1px solid #b7b7b7;
      }
      select {
        border: none;
        outline: none;
        width: 100%;
        height: 40px;
        line-height: 40px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-left: 20px;
      }
      &:after {
        content: "";
        width: 14px;
        height: 8px;
        background: url(../../assets/img/home/arrow-down.png) no-repeat center;
        position: absolute;
        right: 20px;
        top: 41%;
        pointer-events: none;
      }
    }
  }
}
</style>
