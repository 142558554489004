<template>
  <div>
    <div :class="$isWeb ? 'card-h5' : 'card'">
      <div class="withdraw-container">
        <span class="avator">
          <img width="100%" src="../../assets/img/home/smile.png" />
        </span>
        <span class="name">Withdraw grin to wallet</span>
      </div>

      <p style="color: #893EFF; margin: 10px 0" v-if="error">
        ERROR: {{ error }}
      </p>
      <p style="color: #893EFF; margin: 10px 0" v-if="info">INFO: {{ info }}</p>

      <div class="withdraw-detail">
        <div class="item">
          <label class="item-title">Grin address</label>
          <div v-if="grinAddress">
            <input class="item-value" :value="grinAddress" disabled />
            <span class="btn item-btn" @click="reset">reset</span>
          </div>
          <div v-else>
            <input class="item-value" v-model="grinAddressToBind" />
            <span class="btn item-btn" @click="bindGrinAddress">bind</span>
          </div>
        </div>
        <div class="item">
          <label class="item-title">Withdrawable/locked </label>
          <span class="item-text">{{ available }} / {{ locked }} ツ</span>
        </div>
        <div class="item" style="margin-bottom: 10px;">
          <label class="item-title">Withdrawal Amount</label>
          <input
            class="item-value"
            @keydown="handleAmount"
            v-model.number="amount"
          />
          <span class="btn item-btn" @click="withdrawAll">Withdraw all</span>
        </div>
        <p class="item-desc">
          Minimum withdrawal amount 5ツ；<br />
          Withdrawal fee 0.1ツ
        </p>
        <div class="handle-btn">
          <span
            v-if="withdrawable"
            class="btn"
            style="margin-right: 53px"
            @click="withdraw"
            >withdraw</span
          >
          <span
            v-else
            @click="withdraw"
            class="btn btn-disable"
            style="margin-right: 53px"
            >withdraw</span
          >
        </div>
      </div>
    </div>
    <Email4Verify />
  </div>
</template>
<script>
import {
  getCodeToBindGrinAddress,
  getCodeToWithdraw,
  getSlatepack,
  deleteGrinAddress,
} from "../../libs/api.js";
import Email4Verify from "@/components/Email4Verify.vue";

import { minWithdrawAmount, withdrawFee, canWithdraw } from "@/config";

export default {
  props: {},
  data() {
    return {
      grinAddressToBind: "",
      amount: 0,
      fee: withdrawFee,
      min: minWithdrawAmount,
      dialogVisible: false,
      copyText: "copy text",

      error: "",
      info: "",
    };
  },
  components: {
    Email4Verify,
  },
  watch: {
    error: function(newVal) {
      if (newVal) {
        setTimeout(() => (this.error = ""), 5 * 1000);
      }
    },
    info: function(newVal) {
      if (newVal) {
        setTimeout(() => (this.info = ""), 8 * 1000);
      }
    },
  },

  created() {
    this.$messageBus.$on("verifiedByEmail", async (purpose) => {
      if (purpose === "bindGrinAddress") {
        await this.$store.dispatch("getGrinAddress");
      }
      if (purpose === "withdraw") {
        this.$messageBus.$emit("withdraw-inited");
      }
    });
  },

  computed: {
    grinAddress() {
      return this.$store.state.grinAddress;
    },
    total() {
      return parseFloat(this.$store.state.total);
    },
    locked() {
      return parseFloat(this.$store.state.locked);
    },
    available() {
      return parseFloat(this.$store.state.available);
    },
    withdrawable() {
      if (!canWithdraw) {
        return false;
      }
      return (
        this.available > minWithdrawAmount &&
        this.amount + withdrawFee <= this.available &&
        this.amount >= minWithdrawAmount &&
        this.grinAddress &&
        this.amount
      );
    },
  },
  methods: {
    handleAmount(e) {
      //2位尾数
      const precision = 1;
      const re = eval("/^\\d*(\\.?\\d{0," + precision + "})/g");
      e.target.value = e.target.value.match(re)[0] || null;
    },
    async reset() {
      try {
        let resp = await deleteGrinAddress();
        console.log(resp);
        if (resp.status == 204) {
          await this.$store.dispatch("getGrinAddress");
          this.info = "Address reseted.";
        } else {
          this.error = "Something wrong. Try later.";
        }
      } catch (error) {
        console.log(error);
        this.error = "Something wrong. Try later.";
      }
    },
    validateGrinAddress(address) {
      return address.startsWith("grin");
    },

    withdrawAll() {
      if (this.available - withdrawFee < 0) {
        return 0;
      }
      this.amount = this.available - withdrawFee;
    },
    async withdraw() {
      this.dialogVisible = true;
    },
    async withdraw() {
      try {
        let resp = await getSlatepack(this.amount.toFixed(2));
        console.log(resp.data);
        let slatepack = {
          id: resp.data.tx_slate_id,
          s1: resp.data.slatepack,
        };
        this.$store.commit("setSlatepack", slatepack);
        this.$store.commit("openSlatepack");
        this.$messageBus.$emit("withdraw-inited");
      } catch (error) {
        if (error.response) {
          //console.log(error.response.data)
          if (error.response.data.message === "have unconfirmed tx") {
            this.error = "The last withdraw have not finished.";
            return;
          }
          this.error = "Something is wrong. Try later.";
        }
      }
    },

    openEmail4Verify(purpose) {
      this.$store.commit("openEmail4Verify", purpose);
    },

    async bindGrinAddress() {
      if (!this.validateGrinAddress(this.grinAddressToBind)) {
        this.error = "Invalid grin address.";
        return;
      }
      try {
        let resp = await getCodeToBindGrinAddress(this.grinAddressToBind);
        if (resp.data === "ok") {
          this.openEmail4Verify("bindGrinAddress");
        } else {
          this.error = "Something wrong. Try later.";
        }
      } catch (error) {
        if (error.response) {
          let msg = error.response.data.message;
          if (msg === "post email too frequently") {
            this.error = "Request to bind too frequently. Try later.";

            return;
          }
        }
        this.error = "Something wrong. Try later.";
      }
    },

    onCopySuccess() {
      this.$message.success("Copy Success");
      this.withdrawCb();
    },
    onCopyError() {},
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  box-sizing: border-box;
  position: absolute;
  top: -350px; /*no*/
  left: 0;
  right: 0;
  z-index: 9;
  width: 1120px; /*no*/
  height: 614px; /*no*/
  background: #fff;
  margin: 0 auto;
  background: url("../../assets/img/home/card_bg3.png") no-repeat top center;
  padding: 0 70px; /*no*/
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15); /*no*/
  .withdraw-container {
    height: 50px; /*no*/
    margin-top: 85px; /*no*/
    margin-bottom: 28px; /*no*/
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: inline-block;
    }
    .avator {
      width: 50px; /*no*/
      height: 50px; /*no*/
    }
    .name {
      margin-left: 20px; /*no*/
      font-size: 22px; /*no*/
      font-weight: 500;
      line-height: 27px; /*no*/
      color: #101010;
    }
  }
  .withdraw-detail {
    position: relative;
    padding-top: 43px; /*no*/
    border-top: 1px solid #893eff;
    .btn {
      display: inline-block;
      height: 40px; /*no*/
      line-height: 40px; /*no*/
      background-color: #893eff;
      color: #fff;
      font-size: 18px; /*no*/
      font-weight: bold;
      padding: 0 14px; /*no*/
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 37px; /*no*/

      span {
        display: inline-block;
      }
    }
    .item-title {
      font-size: 18px; /*no*/
      line-height: 21px; /*no*/
      color: #676767;
      width: 247px; /*no*/
      text-align: right;
    }
    .item-value {
      box-sizing: border-box;
      width: 436px; /*no*/
      height: 40px; /*no*/
      padding: 10px 12px; /*no*/
      font-size: 16px; /*no*/
      border: 1px solid #b7b7b7; /*no*/
      margin: 0 20px; /*no*/
    }
    .item-text {
      margin: 0 20px; /*no*/
      width: 436px; /*no*/
      height: 40px; /*no*/
      color: #893eff;
      line-height: 40px; /*no*/
    }
    .item-desc {
      padding-left: 266px; /*no*/
      line-height: 19px; /*no*/
      font-size: 15px; /*no*/
      color: #676767;
    }
    .handle-btn {
      height: 40px; /*no*/
      padding-left: 266px; /*no*/
      margin-top: 36px; /*no*/
      .btn-disable {
        background-color: rgba(137, 62, 255, 0.3);
      }
    }
  }
}
.card-h5 {
  box-sizing: border-box;
  position: absolute;
  top: -148px;
  left: 0;
  right: 0;
  z-index: 9;
  width: 626px;
  background: #fff;
  margin: 0 auto;
  background: url("../../assets/img/home/card_bg3.png") no-repeat top center;
  padding: 0 30px 0 50px;
  box-shadow: 0px 0px 30px rgba(137, 62, 255, 0.15);
  .withdraw-container {
    height: 50px;
    margin-top: 54px;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: inline-block;
    }
    .avator {
      width: 50px;
      height: 50px;
    }
    .name {
      margin-left: 20px;
      font-size: 27px;
      font-weight: 600;
      line-height: 32px;
      color: #101010;
    }
  }
  .withdraw-detail {
    position: relative;
    padding-top: 43px;
    border-top: 1px solid #893eff;
    .btn {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      background-color: #893eff;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      padding: 0 14px;
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 37px;
      flex-wrap: wrap;
      span {
        display: inline-block;
      }
    }
    .item-title {
      font-size: 18px;
      line-height: 21px;
      color: #676767;
      margin-bottom: 12px;
    }
    .item-value {
      box-sizing: border-box;
      width: 330px;
      height: 40px;
      padding: 0px 12px;
      font-size: 16px;
      line-height: 40px;
      border: 1px solid #b7b7b7;
      margin-right: 20px;
    }
    .item-text {
      margin-left: 20px;
      color: #893eff;
      font-size: 20px;
      line-height: 40px;
    }
    .item-desc {
      //   padding-left: 266px;
      line-height: 22px;
      font-size: 16px;
      color: #676767;
    }
    .handle-btn {
      height: 40px;
      margin-top: 70px;
      margin-bottom: 65px;
      .btn-disable {
        background-color: rgba(137, 62, 255, 0.3);
      }
    }
  }
}
.copy-container {
  border: 1px dashed #666666;
  padding: 20px 10px;
  min-height: 270px;
  border-radius: 5px;
}
</style>
