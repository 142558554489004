<template>
    <div :class="$isWeb ? 'banner2-h5': 'banner2'">
        <div class="title">
          <p>Withdrawal <span class="icon"/></p>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less">
   .banner2{
       box-sizing: border-box;
       height: 711px;/*no*/
       width: 100%;
       position: relative;
       background-color: #101010;
       .title{
            width:372px;/*no*/
            padding-top: 173px;/*no*/
            color: #fff;
            font-size: 30px;/*no*/
            font-weight: bold;
            line-height: 36px;/*no*/
            color: #FFFFFF;
            text-align: left;
            margin: 0 auto;
            text-align: center;
       }
        .icon {
            display: inline-flex;
            margin-left: 10px;/*no*/
            width: 22px;/*no*/
            height: 22px;/*no*/
            background:url('../../assets/img/home/smile.png') no-repeat center center;
            background-size: cover;
        }
   }
   .banner2-h5{
       box-sizing: border-box;
       height: 480px;
       width: 100%;
       position: relative;
       background-color: #101010;
       .title{
            width:372px;
            padding-top: 120px;
            color: #fff;
            font-size: 40px;
            font-weight: bold;
            line-height: 48px;
            color: #FFFFFF;
            text-align: left;
            margin: 0 auto;
            text-align: center;
       }
        .icon {
            display: inline-flex;
            margin-left: 10px;
            width: 22px;
            height: 22px;
            background:url('../../assets/img/home/smile.png') no-repeat center center;
            background-size: cover;
        }
   } 
</style>