<template>
<el-dialog
    title="Email"
    :visible.sync="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
>
    <p style="color: #893EFF; margin: 10px 0" v-if="error">
        Error: {{ error }}
    </p>
    <p style="color: #893EFF; margin: 10px 0" v-if="info">
        Info: {{ info }}
    </p>

    
    <div class="copy-container">
        <p style="color: #101010; margin: 20px 0">verify code:</p>
        <input class="input" style="width: 320px" v-model="code" />
    </div>

    <span slot="footer" class="dialog-footer">
        <el-button  @click="closeModal" v-if="finished">Finish</el-button>
        <el-button @click="verifyCode" v-else>Verify</el-button>
    </span>
    </el-dialog>

</template>
<script>
import {verifyCode} from '../libs/api.js'

export default {
  name: "Email4Verify",
  data() {
    return {
      error: '',
      info: 'Verify code was sent to you email box.',
      code: '',
      finished: false,
      //dialogVisible: false
    }
  },
  watch: {
    error:function(newVal){
      if(newVal){
        setTimeout(()=> this.error = '', 
        5*1000)
      }
    },
    info:function(newVal){
      if(newVal){
        setTimeout(()=> this.info = '', 
        8*1000)
      }
    }
  },
  computed: {
    dialogVisible(){
      return this.$store.state.openEmail4Verify
    },
    purpose(){
      return this.$store.state.emailVerifyPurpose
    },
  },
  methods: {  
    checkForm(){
      this.error = ''
      this.code = this.code.trim()
      if (!this.code) {
        this.error = 'Please enter your verify code.'
        return false
      }
      return true
    },

    async verifyCode(){
      if(this.checkForm()){
         try{
          let resp = await verifyCode(this.code)
          if(resp.data === 'ok' ){
            if(this.purpose === 'withdraw'){
              this.info = 'Verify finished. Try "withdraw hist" --> "slatepack"'
            }else{
              this.info = 'Verify finished.'
            }
            this.finished = true
            
            this.$messageBus.$emit('verifiedByEmail', this.purpose)
            
            setTimeout(
              ()=> this.closeModal(), 
              5*1000
            )

          }else{
            this.error = 'Something is wrong. Try later.'
          }
        }catch(error){
          if(error.response){
            let msg = error.response.data.message
            if(msg === 'code is wrong'){
              this.error = 'verify code is wrong!'
              return 
            }
          }
          this.error = 'Something is wrong. Try later.'
        }
      }
    },
    closeModal() {
      this.clearup()
      this.$store.commit('closeEmail4Verify')
    },
   
    clearup(){
      this.error = ''
      this.info = 'verify code is sent to your email box.'
      this.code = ''
      this.finished = false
    },
  }
}
</script>
