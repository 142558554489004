<template>
  <div :class="$isWeb ? 'divInput-h5' : 'divInput'">
    <div class="input">
      <input v-model="value" type="text" @blur="inputBlur" disabled />
      <!--<img src="../../assets/img/home/arrow-down.png" class="icon" alt="">-->
    </div>
    <div class="list" v-show="show">
      <ul>
        <li
          @click="getvalue(index, item)"
          v-for="(item, index) in tableData"
          :key="item.index"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      show: false,
      value: "gate",
    };
  },

  methods: {
    openValue() {
      this.show = !this.show;
    },
    getvalue(index, item) {
      this.value = item.name;
      this.show = false;
    },
    inputBlur() {
      setTimeout(() => {
        this.show = false;
      }, 300);
    },
  },
};
</script>
<style lang="less">
.divInput-h5 {
  margin-left: 20px;
  height: 50px;
  .input {
    box-sizing: border-box;
    width: 190px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #cccccc;
    position: relative;
    background: #fff;
  }
  .input .icon {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .list {
    box-sizing: border-box;
    position: absolute;
    top: 80px;
    width: 190px;
    background: #fff;
    border: 1px solid #cccccc;
  }
  .list ul li {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    padding-left: 10px;
  }
  .list ul li:hover {
    background-color: #893eff;
    color: #fff;
  }
}
.divInput {
  margin-left: 20px; /*no*/
  height: 50px; /*no*/
  .input {
    box-sizing: border-box;
    width: 190px; /*no*/
    height: 50px; /*no*/
    line-height: 50px; /*no*/
    border: 1px solid #cccccc;
    position: relative;
    background: #fff;
  }
  .input .icon {
    width: 14px; /*no*/
    height: 14px; /*no*/
    position: absolute;
    right: 20px; /*no*/
    top: 10px; /*no*/
  }
  .list {
    box-sizing: border-box;
    position: absolute;
    top: 80px; /*no*/
    width: 190px; /*no*/
    background: #fff;
    border: 1px solid #cccccc;
  }
  .list ul li {
    box-sizing: border-box;
    width: 100%;
    height: 40px; /*no*/
    cursor: pointer;
    line-height: 40px; /*no*/
    padding-left: 10px; /*no*/
  }
  .list ul li:hover {
    background-color: #893eff;
    color: #fff;
  }
}
ul li {
  list-style: none;
}

.input input {
  box-sizing: border-box;
  border: none;
  outline: none;
  width: 100%;
  background: #fff;
}
</style>
