<template>
<div>
  <el-dialog
      title="Grin Withdraw(Slatepack) - 1"
      :visible.sync="dialogVisible"
      width="400"
      :before-close="handleClose"
      :close-on-click-modal="false"
  >
  <p style="color: #893EFF; margin: 10px 0" v-if="info">
      INFO: {{ info }}
  </p>

    <p style="color: #893EFF; margin: 10px">Please finalize this tx in 30 minutes. </p>
    <div class="copy-container" style="margin-left:40px;margin-top:40px">
      <p v-for="line in slatepackToDispaly" :key="line.id" style="margin-top:4px;margin-bottom:4px">
        {{line}}
      </p>
      </div>
      
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal" v-if='copied'>Finish</el-button>
      <el-button @click="closeModal" v-else>Cancel</el-button>

      <el-button
        type="primary"
        @click="copy"
      >
        Copy
      </el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>

export default {
  name: "Slatepack",
  data() {
    return {
      copied: false,
      info: ''
    }
  },
  watch: {
    copied:function(newVal){
      if(newVal){
        setTimeout(()=>this.copied = false, 
        8*1000)
      }
    },
    info:function(newVal){
        if(newVal){
            setTimeout(()=> this.info = '', 
            8*1000)
        }
    }
  },
  computed: {
    openSlatepack(){
      return this.$store.state.openSlatepack
    },
    dialogVisible(){
      return this.$store.state.openSlatepack
    },
    slatepack(){
      if(this.$store.state.slatepack && this.$store.state.slatepack.s1){
        return this.$store.state.slatepack.s1
      }
      return ''
    },

    slatepackToDispaly: function() {
      if(this.slatepack){
        let res = this.slatepack.split(' ')
        let head = res.shift()
        let end = res.pop()
        let result = [head, ]
        let line = []
        for (let i = 0; i < res.length; i++) {
          if(i%3 === 0){
            if(line.length>0)result.push(line.join(' '))
            line = [res[i]]
          }else{
            line.push(res[i])
          }
        }
        result.push(end)
        return result
      }else{
        return null
      }
    }
  },

  methods: {
    handleClose(){

    },
    closeModal() {
      this.copied = false
      this.info = ''
      this.$store.commit('closeSlatepack')
    },
    
    async copy(){
      this.$copyText(this.slatepack)
      this.copied = true
      this.info = 'slatepack message is copied'
    },  
  }
}
</script>
<style>
#slatepack_box {
  height:400px;
  overflow-y: scroll;
  border-style:dashed; 
  border-width:2px;
  border-color:#dbdbdb;
  background-color:#f6f9fe;
  outline: none;
}

</style>
